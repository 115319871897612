.paragraph {
    font-size: 18px;
    padding-bottom: 0rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0rem;
    margin-top: 0rem;
}

.genreButton {
    border: 1px solid grey;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    min-width: 100px;
}

.genreButton.selected {
  background-color: #007bff; /* Change to the desired background color */
  color: #fff; /* Change to the desired text color */
  border: 2px solid #007bff; /* Change to the desired border color */
}

.genreButton:hover {
    /*background-color: lightskyblue;*/
}

.genreButton:active {
    background-color: lightblue;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border-radius: 10px;
    min-height: 200px;
    min-width: 400px;
    float: left;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.container {
    /*margin-top: 30px;*/
    text-align: center;
    /*flex: 1;*/
}

.title-bar {
    font-size: 20px;
    letter-spacing: 0.05em;
    color: grey;
    padding-left: 50px;
    text-align: left;
    padding-bottom: 0px;
    padding-top: 5px;
    background-color: white;
}

.box {
    color: white;
    text-align: center;
    background-color: #f8f9fa;
    /*border: 1px solid #ccc;*/
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgb(178 169 169 / 30%);
    border-radius: 10px;
}

.separator {
    border-top: 1px solid #6c6a6a;
    margin: 20px 0;
}

.paymentbutton:hover {
    background-color: lightskyblue;
    box-shadow: 0 3px #999;
}

.paymentbutton:active {
    background-color: lightblue;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}

.footer {
    background-color: grey;
    min-height: 40px;
    text-align: center;
    vertical-align: middle;
    color: lightgrey;
    letter-spacing: 0.05em;
    padding-top: 10px;
    margin-top: 100px;
    min-height: 100px;
}